import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { updateToken } from "../../api/api";
import { LayoutContext } from "../../App";
import ReverseCodingLogo from "../../assets/reverse-coding-logo.png";
import CountDown from "../CountDown/CountDown";
import moment from "moment";
import "./Navbar.scss";

const Navbar: React.FC = () => {
  const { userDetails } = useContext(LayoutContext);
  const [user, setUser] = userDetails;
  const location = useLocation();
  const navigate = useNavigate();
  const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false);

  const token = localStorage.getItem("UserAuthToken");

  const logout = () => {
    const yes = window.confirm("Do you want to log out?");
    if (yes) {
      localStorage.clear();
      updateToken(token);
      navigate("/login");
    }
  };
  return (
    <header
      className="navbar-section sticky"
      style={{
        display:
          location.pathname === "/instructions" ||
          location.pathname === "/login" ||
          (location.pathname === "/leaderboard" && token === null)
            ? "none"
            : "block",
      }}
    >
      <div className="navbar-container container">
        {/* <div className="page-wrapper wrapper"> */}
        <div className="nav-wrapper wrapper">
          <nav className="navbar">
            <div className="logo">
              <img src={ReverseCodingLogo} alt="Company Logo" />
            </div>
            <div
              className={`menu-toggle ${mobileNavOpen ? "is-active" : ""}`}
              id="mobile-menu"
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </div>
            {/* <ul
              className={`nav ${mobileNavOpen ? "mobile-nav" : ""} no-search`}
            >
              <li
                className={`nav-item ${
                  location.pathname === "/" ? "active" : ""
                }`}
                onClick={() => {
                  setMobileNavOpen(false);
                  navigate("/");
                }}
              >
                Contest
              </li>
              <li
                className={`nav-item ${
                  location.pathname === "/leaderboard" ? "active" : ""
                }`}
                onClick={() => {
                  setMobileNavOpen(false);
                  navigate("/leaderboard");
                }}
              >
                Leaderboard
              </li>
            <li
                    className={`nav-item ${
                      location.pathname === "/third-year" ? "active" : ""
                    }`}
                    onClick={() => {
                      setMobileNavOpen(false);
                      navigate("/third-year");
                    }}
                  >
                    3rd-Year
                  </li>
                  <li
                    className={`nav-item ${
                      location.pathname === "/fourth-year" ? "active" : ""
                    }`}
                    onClick={() => {
                      setMobileNavOpen(false);
                      navigate("/fourth-year");
                    }}
                  >
                    4th-Year
                  </li>
            </ul> */}
            <CountDown
              startTime={moment(new Date()).format()}
              endTime={moment(new Date(user?.startTime[0]))
                .add(1, "hours")
                .format()}
            />
            <div className="nav-col-3">
              <ul
                className={`nav ${mobileNavOpen ? "mobile-nav" : ""} no-search`}
              >
                <li
                  className={`nav-item ${
                    location.pathname.includes("/contest") ? "active" : ""
                  }`}
                  onClick={() => {
                    setMobileNavOpen(false);
                    navigate(`/contest/${user?.questions?.[0]}`);
                  }}
                >
                  Contest
                </li>
                <li
                  className={`nav-item ${
                    location.pathname === "/leaderboard" ? "active" : ""
                  }`}
                  onClick={() => {
                    setMobileNavOpen(false);
                    navigate("/leaderboard");
                  }}
                >
                  Leaderboard
                </li>
              </ul>
              <div className="user-profile">
                <img src={user?.profileImageUrl} className="user-img" alt="" />
                <div className="details">
                  <h4>{user?.name}</h4>
                  <span>{user?.email}</span>
                </div>
              </div>
              <button className="btn" onClick={() => logout()}>
                Logout
              </button>
            </div>
            {/* </div> */}
          </nav>
        </div>
        {/* </div> */}
      </div>
    </header>
  );
};

export default Navbar;
